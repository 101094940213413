import { render, staticRenderFns } from "./PackageList.vue?vue&type=template&id=047334e8&scoped=true&MaterialType=3"
import script from "./PackageList.vue?vue&type=script&lang=js&MaterialType=3"
export * from "./PackageList.vue?vue&type=script&lang=js&MaterialType=3"
import style0 from "./PackageList.vue?vue&type=style&index=0&id=047334e8&scoped=true&lang=css&MaterialType=3"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047334e8",
  null
  
)

export default component.exports